<template>
    <div class="fb-flex fb-flex-col fb-w-full fb-bg-gray-50 fb-mx-auto"
         ref="fullscreenEl"
         :class="currentFlowClasses"
         :style="currentFlowStyles"
    >
        <div
            class="fb-flex-1 fb-flex fb-flex-col fb-mx-auto"
            :class="currentSlideClasses"
            :style="currentSlideStyles"
            ref="container"
        >
            <div
                v-if="errors.length && !errorStore.useSlideErrorBlock"
                v-html="errors.map(err => err.messages.join('<br>')).join('<br>')"
                class="fb-bg-red-100 fb-border fb-border-red-400 fb-text-red-700 fb-px-4 fb-py-3 fb-rounded fb-relative fb-m-5"
            >
            </div>
            <Loading v-if="flowStore.showLoading" />
            <div v-if="!flowStore.showLoading">
                <TemplateRenderer
                    v-if="useTemplateRenderer"
                    :slide-template="currentSlideTemplate"
                    :nested-slide="currentSlide"
                />
                <BlockRenderer
                    v-else
                    v-for="block in tree"
                    :key="block.id"
                    :block="block"
                />
            </div>
        </div>
        <div id="preloader" class="fb-invisible fb-absolute fb-z-0 fb-h-0">
            <img src="" alt="" />
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, onMounted, ref, Ref, watch} from "vue";
import Slide from "@flow-builder/core/src/Slides/Slide.ts";
import {TransitionType, useFlowStore} from "../../Stores/flow.ts";
import {FadeTransition} from "../../Services/Transition.ts";
import Loading from "../../Components/Loading.vue";
import BlockRenderer from "./BlockRenderer.vue";
import {useErrorStore} from "../../Stores/errors.ts";
import TemplateRenderer from "../BlockRenderers/Layouts/TemplateRenderer.vue";
import calculatorEventService from "../../Services/Analytics/CalculatorEventService.ts";
import {
    useBackgroundFlowClasses,
    useBackgroundSlideClasses,
    useBackgroundStyles,
    useSliderWidthClasses
} from "@flow-builder/core/src/Components/Renderer/Composables/useStylesTransformer.ts";
import {FlowStyle} from "@flow-builder/core/src/Flows/Flow.ts";

interface Props {
    slide: Slide | null,
    slideTemplate: Slide | null,
}
const props = defineProps<Props>();

const container: Ref<HTMLElement|null> = ref(null);
const fullscreenEl: Ref<HTMLElement|null> = ref(null);

const flowStore = useFlowStore();
const errorStore = useErrorStore();

const currentSlide: Ref<Slide|null> = ref(null);
const currentSlideTemplate: Ref<Slide|null> = ref(null);

const currentFlowClasses: Ref<string[]> = ref([]);
const currentFlowStyles: Ref<{[key: string]: string}> = ref({});
const currentSlideClasses: Ref<string[]> = ref([]);
const currentSlideStyles: Ref<{[key: string]: string}> = ref({});

const useTemplateRenderer: Ref<boolean> = ref(false);

const tree = computed(() => currentSlide.value?.hierarchy ?? []);

const initialize = () => {
    currentSlide.value = props.slide;
    currentSlideTemplate.value = props.slideTemplate;
    updateAllStyles();
};

onMounted(() => initialize());

const updateTemplateRenderer = () => {
    currentSlide.value = props.slide;
    currentSlideTemplate.value = props.slideTemplate;
}

/**
 * Fade in/out transition function
 */
watch(() => props.slide, async (newValue: Slide, oldValue: Slide | null) => {
    flowStore.startRendererTransition();
    if (flowStore.transitionType === TransitionType.TemplateToTemplate) {
        updateTemplateRenderer();
        useTemplateRenderer.value = true;
        return;
    }

    const transition = new FadeTransition();

    if (!oldValue) { // Handles initial slide
        useTemplateRenderer.value = !!flowStore.sliderService.currentSlide?.template;
        if (useTemplateRenderer.value) updateTemplateRenderer();

        currentSlide.value = newValue;
        updateFlowStyles();
        updateSlideStyles();
        flowStore.updateGlobalStyleVariables();
        await flowStore.checkImagesArePreloaded();

        flowStore.fireSlideLoadedEvent(newValue);
        calculatorEventService.trackEvents(flowStore.sliderService.currentSlide?.name ?? '');
    } else {
        await new Promise(res => setTimeout(() => res(), 1));
        // Fade out and in from the very outer container if the Flow Background has been changed
        const targetContainer = flowStore.flowBackgroundHasChanged ? fullscreenEl.value : container.value;

        transition.out(targetContainer as HTMLElement).then(async () => {
            if (flowStore.transitionType === TransitionType.TemplateToScreen) {
                useTemplateRenderer.value = false;
            }
            // If this is a Screen to Template transition, update the Template Renderer, but the fade-in will still be from this container
            else if (flowStore.transitionType === TransitionType.ScreenToTemplate) {
                updateTemplateRenderer();
                useTemplateRenderer.value = true;
            }

            // All changes which should be invisible should happen here, before transition.in()
            currentSlide.value = newValue;
            updateAllStyles();
            flowStore.updateGlobalStyleVariables();
            await flowStore.checkImagesArePreloaded();

            transition.in(targetContainer as HTMLElement).then(() => flowStore.commitWatchDog());
            flowStore.fireSlideLoadedEvent(newValue);
            calculatorEventService.trackEvents(flowStore.sliderService.currentSlide?.name ?? '');
            flowStore.finishRendererTransition();
        });
    }

    flowStore.sliderService.handleSlideAutoProgress(newValue);
});

/**
 * Update styles and classes on the outer flow container
 */
const updateFlowStyles = () => {
    const styles = {};
    const classes = [];
    const flowBackground = flowStore.flow?.getFlowBackground();

    if (flowBackground) {
        useBackgroundStyles(flowBackground, styles);
        useBackgroundFlowClasses(flowBackground, classes, flowStore.flow?.getStyle() as FlowStyle);
    }

    currentFlowStyles.value = styles;
    currentFlowClasses.value = classes;
}

/**
 * Update styles and classes on the slide container
 */
const updateSlideStyles = () => {
    if (!props.slide) return;

    const targetSlide = props.slideTemplate
        ? props.slideTemplate
        : props.slide;

    const targetStyles = props.slideTemplate
        ? props.slideTemplate.slideStyles
        : props.slide.slideStyles;

    const styles = {};
    const classes = [];

    useSliderWidthClasses(targetSlide.width, FlowStyle.Slider, classes);
    useBackgroundStyles(targetStyles, styles);
    useBackgroundSlideClasses(targetSlide.slideStyles, classes, flowStore.flow?.getStyle() as FlowStyle);

    currentSlideStyles.value = styles;
    currentSlideClasses.value = classes;
}

const resetScrollHeight = () => {
    document.scrollingElement.scrollTop = 0;
}

const updateAllStyles = () => {
    updateFlowStyles();
    updateSlideStyles();
    resetScrollHeight();
}

const errors = computed(() => errorStore.slideErrors.getSlideErrors(flowStore.sliderService.currentSlide?.id ?? ''));

</script>
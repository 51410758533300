import axios, {AxiosInstance, AxiosResponse} from "axios";
import {useFlowStore} from "../../Stores/flow.ts";

export interface ClientWindow extends Window {
    previewMode: boolean;
}

declare let window: ClientWindow;

export default class FlowApiService {
    constructor(protected baseUrl: string, protected version: string) {}

    protected axios(): AxiosInstance {
        return axios.create({
            baseURL: `${this.baseUrl}/api/${this.version}`
        });
    }

    getFlow(flowKey: String): Promise<AxiosResponse<any>> {
        return axios.get(`/flows/init/${flowKey}`);
    }

    handshake(flowKey: string, engines: string[], service: string|undefined = undefined, deletedRevision = false): Promise<AxiosResponse<any>> {
        let params = {};
        const flowStore = useFlowStore();

        for (const [key, value] of new URLSearchParams(window.location.search).entries()) {
            params = {...params, [key]:value}
        }

        return this.axios().get('flows/consumer/handshake', {
            params: {
                flow_key: flowKey,
                engines,
                service,
                ...params,
                url_start: document.referrer.length > 0 ? document.referrer : window.location.href,
                calculator_source: `${flowStore.flow?.getFlowName() ?? 'flow'}.${flowStore.flowKey}`,
                deleted_revision_fetched: deletedRevision ? 1 : 0,
            }
        })
    }
    update(payload: Object, token: string):  Promise<AxiosResponse<any>>{
        return this.axios().post('flows/consumer/update', {payload: JSON.stringify(payload), bearer_token: token});
    }
}
<template>
    <div class="fb-transition-all fb-duration-500 fb-inline-block fb-whitespace-pre-wrap fb-px-1"
          :class="{'fb-animate-pulse fb-text-gray-300': showPulse, [itemClasses]: true}"
        :style="getTextStyle()"
    >
        {{ value }}
    </div>
</template>

<script setup lang="ts">
import {EngineValue, useEngineStore} from "../../../Stores/engines.ts";
import {computed, CSSProperties} from "vue";
import {getLineHeight, LineHeightAttributes} from "@flow-builder/core/src/Components/Renderer/Composables/useLineHeight.ts";
import {MobileFontSizeOverride, TipTapItem} from "@flow-builder/core/src/Blocks/Core/Text/RichText.ts";

interface Props {
    item: TipTapItem,
    shortcode: string,
    mobileFontSizeOverride: MobileFontSizeOverride
}
const props = defineProps<Props>();

const store = useEngineStore();

const showPulse = computed(() => {
    return shortcodeValue.value?.value && shortcodeValue.value?.loading;
});

const shortcodeValue = computed(() => {
    const split = (props.shortcode as String).split(".");
    let value: EngineValue|null = null

    switch(split[0]) {
        case "outputs":
            if(store.outputs[split[1]] && store.outputs[split[1]][split[2]]) {
                value = store.outputs[split[1]][split[2]];
            } else {
                value = {value: '-', loading: true}
            }
            break;
        case "inputs":
            value = {value: store.getEngineInputByKey(split[2]), loading: false};
            break;
    }

    return value;
});

const itemClasses = computed(() => {
    const classes = [];

    if(props.item && props.item.marks) {
        props.item.marks.forEach(item => {
            switch(item.type) {
                case 'italic':
                    classes.push('fb-italic');
                    break;
                case 'bold':
                    classes.push('fb-font-bold')
                    break;
                case 'underline':
                    classes.push('fb-underline')
                    break;
                case 'strike':
                    classes.push('fb-line-through')
                    break;
                case 'textStyle':
                    if (item.attrs?.solColor && item.attrs.solColor !== 'auto')
                        classes.push(`fb-text-sol-${item.attrs.solColor}`);
                    break;
            }
        });
    }

    if(props.item && props.item.attrs && props.item.attrs.solColor && !showPulse.value) {
        classes.push(`fb-text-sol-${props.item.attrs.solColor}`);
    }

    return classes.join(" ");
});

const getTextStyle = () => {
    const styles: CSSProperties = {};

    if (props.mobileFontSizeOverride?.fontSize)
        styles.fontSize = props.mobileFontSizeOverride.fontSize;
    if (props.mobileFontSizeOverride?.lineHeight)
        getLineHeight({ lineHeight: props.mobileFontSizeOverride.lineHeight, fontSize: props.mobileFontSizeOverride.fontSize ?? "" }, styles);

    props.item.marks?.forEach(data => {
        switch (data.type) {
            case 'textStyle':
                if (!props.mobileFontSizeOverride?.fontSize && data.attrs?.fontSize && data.attrs.fontSize !== 'auto')
                    styles.fontSize = data.attrs?.fontSize;
                if (!props.mobileFontSizeOverride?.lineHeight && data.attrs?.lineHeight)
                    getLineHeight(data.attrs as LineHeightAttributes, styles);
                break;
        }
    });

    return styles;
}

const value = computed(() => {
    const value = shortcodeValue.value;

    if(value === null || value.loading)
        return value?.value ? value?.value : "-";

    return value.value;
});
</script>
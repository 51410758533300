type GeographicLocation = {
  address_components: Array<{
    long_name: string;
    short_name: string;
    types: Array<string>;
  }>;
};

function getObject(): GeographicLocation | null {
  const geographicLocation = sessionStorage.getItem("geolocation-location");

  let location: GeographicLocation | null;

  if (typeof geographicLocation !== "object") {
    location = geographicLocation ? JSON.parse(geographicLocation) : null;
  } else {
    location = geographicLocation;
  }

  return location;
}

export function getData(key: string): string | null {
  const location = getObject();

  return (
    location?.address_components?.filter(function (item) {
      return item.types.includes(key);
    })[0].long_name ?? null
  );
}

interface WatchDogScript {
    commit(): void,
    getUniqueIdentifier(): string,
    startCapturing(): void,
    stopCapturing(): void,
}

export interface WatchDogServiceContract {
    startCapture(): void,
    stopCapture(): void,
    commitEvents(): void,
    awaitIdentifier(): Promise<boolean>,
    getIdentifier(): string|null,
}

const targetElementSelector = '#flow-client';

export class WatchDogService implements WatchDogServiceContract {

    watchDog: WatchDogScript | null = null;
    identifier: string | null = null;

    constructor(flowId: string) {
        const targetElement = document.querySelector(targetElementSelector);
        if ('WatchDog' in window && targetElement) {
            //@ts-ignore
            this.watchDog = new window.WatchDog(targetElement, {
                apiVersion: 'v1',
                campaign: flowId
            });
        }
        else {
            console.error(`Error starting WatchDog service, script not loaded.`);
        }
    }

    async awaitIdentifier(): Promise<boolean> {
        const identifierTimeout = 10000;
        const identifierInterval = 250;
        return new Promise<boolean>(res => {
            let timer = 0;
            const awaitingIdentifier = setInterval(() => {
                if (timer > identifierTimeout) {
                    clearInterval(awaitingIdentifier);
                    res(false);
                }
                if (this.watchDog?.getUniqueIdentifier()) {
                    this.identifier = this.watchDog?.getUniqueIdentifier();
                    clearInterval(awaitingIdentifier);
                    res(true);
                }
                timer += identifierInterval;
            }, identifierInterval);
        }).catch(e => {
            console.error(e);
            return false;
        });
    }

    startCapture(): void {
        if (this.watchDog)
            this.watchDog.startCapturing();
    }

    stopCapture(): void {
        if (this.watchDog)
            this.watchDog.stopCapturing();
    }

    commitEvents(): void {
        if (this.watchDog)
            this.watchDog.commit();
    }

    getIdentifier(): string|null {
        if(this.watchDog)
            return this.watchDog.getUniqueIdentifier();

        return null;
    }
}

export class WatchDogDummyService implements WatchDogServiceContract {

    identifier;
    constructor(flowId: string) {
        this.identifier = flowId;
    }

    startCapture(): void {
        console.log(`Dummy WatchDog service starting capture on flow - ${this.identifier}`);
    }

    stopCapture(): void {
        console.log(`Dummy WatchDog service stopping capture on flow - ${this.identifier}`);
    }

    commitEvents(): void {
        console.log(`Dummy WatchDog service committing events on flow - ${this.identifier}`);
    }

    awaitIdentifier(): Promise<boolean> {
        return new Promise(res => res(true));
    }

    getIdentifier(): string|null {
        return null;
    }
}

import * as rrweb from 'rrweb';
import * as Sentry from "@sentry/browser";
import axios from "axios";

export class WatchdogV2 {
    events: any[] = []
    ignoredEvents: any[] = []
    videoId: string | null = null
    site: URL;
    token: string;

    public constructor(site: string | null, token: string | null) {
        if (!site) {
            throw new Error(`Site must not be an empty value. ${site} passed.`)
        }

        if (!token) {
            throw new Error(`Token must not be an empty value. ${token} passed.`)
        }

        try {
            this.site = new URL(site)
        } catch (error: any) {
            throw new Error(error.message)
        }

        this.token = token
    }

    public getUrl(path: string) {
        return this.site.href + `api/${path}`
    }

    public async init() {
        const recorder = this.startRecording()

        try {
            await this.requestVideoId().then(() => {
                this.updateVideo(
                    'patch',
                    this.getUrl(`videos/${this.videoId}`),
                    {
                        events: this.events
                    },
                )
            })
        } catch (error: any) {
            const status = error?.status;
            let message = ''

            if (status) {
                message += `Failed with status: ${status}.`
            }

            message += ' ' + error?.response?.data?.message ?? error?.message ?? error

            message = `Unable to retrieve video id. Recording stopped. ${message}`

            this.logError(
              new Error(message),
            );

            /* v8 ignore next 3 */
            if (recorder) {
                recorder();
            }
        }
    }

    /**
     * @param exception
     * @param context
     * @private
     */
    logError(exception: Error | any, context: any | object | null = null) {
        console.error(exception.message)

        if (context) {
            Sentry.setContext('watchdog', context)
        }

        Sentry.captureException(exception, {
            tags: {
                area: "Watchdog"
            }
        });
    }

    /**
     * @private
     * @param event
     */
    async processEvent(event: any) {
        if (event.type === undefined || event.data === undefined || event.timestamp === undefined) {
            this.logError(new Error('Event is not valid'), {
                event: JSON.stringify(event),
            })

            return
        }

        if (this.shouldIgnoreEvent(event)) {
            this.ignoredEvents.push(event)
            return
        }

        if (this.videoId) {
            await this.updateVideo(
                'post',
                this.getUrl(`videos/${this.videoId}/events`),
                {
                    event
                },
            );
        }

        this.events.push(event)
    }

    /**
     * @private
     * @param event
     */
    shouldIgnoreEvent(event: any) {
        return event?.type == 3
            && Array.isArray(event?.data?.attributes)
            && event?.data?.attributes.length > 0
            && typeof event?.data?.attributes[0]?.attributes?.style == 'object'
            && event?.data?.attributes[0]?.attributes?.style.hasOwnProperty('opacity')
    }



    /**
     * @private
     */

    /* v8 ignore next 8 */
    startRecording() {
        let self = this

        return rrweb.record({
            emit(event: any) {
                self.processEvent(event).then(r => r)
            },
            sampling: {
                mousemove: false,
                mouseInteraction: true,
                scroll: 150,
                media: 800,
                input: 'all'
            },
            slimDOMOptions: {
                comment: true
            }
        })
    }

    /**
     * @private
     */
    async requestVideoId() {
        this.videoId = sessionStorage.getItem('watchdog-video-id');

        if (!this.videoId) {
            await axios.post(this.getUrl('videos'), {}, {
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            })
                .then(response => {
                    this.videoId = response.data.data.video.id

                    if (!!this.videoId) {
                        sessionStorage.setItem('watchdog-video-id', this.videoId)
                    } else {
                        const message = 'Request was successful, but no video id was found.'

                        this.logError(new Error(message), {
                            message
                        })
                    }
                })
        }
    }

    /**
     * @private
     * @param method
     * @param url
     * @param data
     */
    async updateVideo(method: string, url: string, data: object | any) {
        return axios.request({
            method,
            url,
            data,
            headers: {
                'Authorization': `Bearer ${this.token}`
            },
        }).catch(error => {
            const status = error?.status;
            let message = ''

            if (status) {
                message += `Failed with status: ${status}.`
            }

            message += ' ' + error?.response?.data?.message ?? error?.message ?? error

            message = `Could not update video (${this.videoId}): ${message}`

            type Context = {
                event: undefined | string
                events: undefined | string
            }

            const context : Context = {
                event: undefined,
                events: undefined,
            }

            if (data.hasOwnProperty('event')) {
                context.event = JSON.stringify(data.event)
            }

            if (data.hasOwnProperty('events')) {
                context.events = JSON.stringify(data.events)
            }

            this.logError(
                new Error(message),
                context
            );
        })
    }
}

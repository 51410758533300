import {defineStore} from "pinia";
import SlideErrors from "../Errors/SlideErrors.ts";
import {useFlowStore} from "./flow.ts";
import {ref, Ref, watch} from "vue";
import Slide from "@flow-builder/core/src/Slides/Slide.ts";
import Block from "@flow-builder/core/src/Blocks/Core/Block.ts";

export const useErrorStore = defineStore('errors', () => {
    const slideErrors = new SlideErrors();

    const flowStore = useFlowStore();

    const useSlideErrorBlock: Ref<boolean> = ref(false);

    const checkForErrorBlock = (slide: Slide) => {
        if (!slide) return false;
        const checkBlocks = (blocks: Block[]) => {
            let errorBlockExists = false;
            for (let i=0; i<blocks.length; i++) {
                if (errorBlockExists) break;
                if (blocks[i].blocks?.length) {
                    if (checkBlocks(blocks[i].blocks as Block[])) {
                        return true;
                    }
                }
                else {
                    if (blocks[i].type === 'slide-error') {
                        errorBlockExists = true;
                    }
                }
            }
            return errorBlockExists;
        }
        return checkBlocks(slide.hierarchy);
    }

    const initialize = () => {
        watch(() => flowStore.sliderService.currentSlide, (newVal) => {
            if (newVal) {
                useSlideErrorBlock.value = checkForErrorBlock(flowStore.sliderService.currentSlide as Slide);
            }
        });
        useSlideErrorBlock.value = checkForErrorBlock(flowStore.sliderService.currentSlide as Slide);
    }



    return {
        slideErrors,
        useSlideErrorBlock,

        initialize,
    }
})
<template>
    <div
        class="fb-flex fb-items-center fb-p-5 fb-gap-3" :key="instance.numberOfInputs"
        :class="{'fb-pointer-events-none fb-opacity-20': saving, [blockAlignment]: true}"
    >
        <input
            v-for="input in inputs"
            :key="input.key"
            class="fb-w-10 fb-h-12"
            :class="[textAlignment, verificationStyle]"
            :disabled="instance.disabled"
            :type="instance.inputType"
            v-model="input.value"
            @keydown="handleChange"
            @paste="handlePaste"
            @input="handleAutocomplete"
            ref="inputRefs"
            inputmode="numeric"
            autocomplete="one-time-code"
        >
    </div>
</template>

<script setup lang="ts">
import PhoneVerification, {
    PhoneVerificationStyle
} from "@flow-builder/core/src/Blocks/Core/Inputs/PhoneVerification.ts";
import {computed, onMounted, ref, Ref, watch} from "vue";
import BlockPayload from "../../../Payload/BlockPayload.ts";
import {SlideUpdateEvent, useFlowStore} from "../../../Stores/flow.ts";
import {usePayloadStore} from "../../../Stores/payload.ts";
import {useConsumerStore} from "../../../Stores/consumer.ts";
import {ErrorResponse} from "../../../Errors/SlideErrors.ts";
import {useErrorStore} from "../../../Stores/errors.ts";
import {BlockAlignment, BlockJson} from "@flow-builder/core/src/Blocks/Core/Block.ts";
import {SlideActionType} from "@flow-builder/core/src/Blocks/Core/Actions/SlideAction.ts";
import {ConversionEvents} from "../../../Services/Analytics/Events/Conversion.ts";

interface PhoneVerificationInput {
    key: string,
    value: any
}

interface Props {
    instance: PhoneVerification
}

const flowStore = useFlowStore();
const payloadStore = usePayloadStore();
const consumerStore = useConsumerStore();
const errorStore = useErrorStore();

const inputs: Ref<PhoneVerificationInput[]> = ref([]);
const saving = ref(false);
const inputRefs = ref([]);
const debug = ref(null);

const props = defineProps<Props>();

const initialized = () => {
    for (let input = 1; input <= props.instance.numberOfInputs; input++) {
       inputs.value.push({
           key: `input_${input}`,
           value: null
       });
    }
};

onMounted(() => initialized());

const buildPayload = () => {
    return new BlockPayload(
        flowStore.sliderService.currentSlide?.id ?? '',
        {
            [props.instance.backendIdentifier]: inputs.value.reduce((acc, cur) => acc + (cur.value ?? ''), '')
        },
        props.instance.id
    );
};

const handleChange = async (e: KeyboardEvent) => {
    if(!((e.metaKey) || (e.ctrlKey)))
        e.preventDefault();
    const el = e.target;
    const index = inputRefs.value.indexOf(el);
    const keyCode = e.keyCode;

    if(index !== -1) {
        if (e.key === 'Enter' && inputsAreValid()) {
            updateEnginesAndProgress();
            return;
        }
        if (keyCode === 8 || keyCode === 46) {
            if((inputs.value[index].value === null || inputs.value[index].value === "") && index > 0)
                inputs.value[index - 1].value = null
            else
                inputs.value[index].value = null
        } else {
            if(isNaN(e.key as Number)) {
                return;
            }

            inputs.value[index].value = e.key;
        }
    }

    payloadStore.flowPayload?.setBlockPayload(buildPayload());
    changeFocus(e.target as HTMLElement, e.keyCode);

    if (props.instance.autoProgress && inputs.value.reduce((acc, cur) => acc + (cur.value ?? ''), '').length === Number(props.instance.numberOfInputs)) {
        updateEnginesAndProgress();
    }
}

const updateEnginesAndProgress = async () => {
    saving.value = true;

    const newEvent: SlideUpdateEvent = {
        type: SlideActionType.NextSlide,
        trigger: props.instance as BlockJson,
    }

    await flowStore.update(
        payloadStore.flowPayload?.getSlidePayload(flowStore.sliderService.currentSlide?.id ?? ''),
        consumerStore.bearerToken,
        newEvent
    ).then(() => {
        conversionEvents();
    }).catch(e => {
        errorStore.slideErrors.processErrorResponse(e.response as ErrorResponse, flowStore.sliderService.currentSlide?.id ?? '');
    });

    setTimeout(() => saving.value = false, 300);
}

const changeFocus = (el: HTMLElement, keyCode: number) => {
    const index = inputRefs.value.indexOf(el);

    if (keyCode === 8 || keyCode === 46) { //delete and backspace
        el.setAttribute('value', "");

        // Prevent changing the previous input value by mistake on this tick
        setTimeout(() => {
            if (index !== 0) {
                inputRefs.value[index -1].focus();
            }
        })


        return;
    }

    // Prevent changing the next input value by mistake on this tick
    setTimeout(() => {
        if (index + 1 < Number(props.instance.numberOfInputs)) {
            inputRefs.value[index + 1].focus();
        }
    })
};

const textAlignment = computed(() => {
    switch(props.instance.textAlignment) {
        case BlockAlignment.Left:
            return "fb-text-left";
        case BlockAlignment.Right:
            return "fb-text-right";
        case BlockAlignment.Center:
        default:
            return "fb-text-center";
    }
})

const blockAlignment = computed(() => {
    switch(props.instance.blockAlignment) {
        case BlockAlignment.Left:
            return "fb-justify-start";
        case BlockAlignment.Right:
            return "fb-justify-end";
        case BlockAlignment.Center:
        default:
            return "fb-justify-center";
    }
});

const verificationStyle = computed(() => {
    return props.instance.verificationStyle === PhoneVerificationStyle.Legacy
        ? 'fb-border-gray-500 fb-border-b-2 focus:fb-outline-none'
        : 'fb-border-blue-300 fb-border fb-rounded-md'
});

const autofillInputs = (code: string) => {
    for(let i = 0; i < props.instance.numberOfInputs; i++) {
        inputs.value[i].value = null;
    }
    for(let i = 0; i < code.length; i++) {
        inputs.value[i].value = /\d+/.test(code[i])
            ? code[i]
            : '';
    }

    inputRefs.value[(code.length === props.instance.numberOfInputs ? code.length - 1 : code.length)]?.focus();

    if (inputsAreValid()) {
        payloadStore.flowPayload?.setBlockPayload(buildPayload());
        if (props.instance.autoProgress) updateEnginesAndProgress();
    }
}

const handlePaste = (e: ClipboardEvent) => {
    e.preventDefault();
    const data = e.clipboardData?.getData("text") ?? "";
    const code = data.slice(0, props.instance.numberOfInputs);

    autofillInputs(code);
}

const handleAutocomplete = (e: InputEvent) => {
    e.preventDefault();

    const codeDigits = e.data.replace(/\D/g, '');

    if (codeDigits.length > 1) {
        autofillInputs(codeDigits);
    }
    else {
        const target: HTMLInputElement | null = e.currentTarget as HTMLInputElement;
        const value = target?.value;
        const elIndex = inputRefs.value.indexOf(target);

        if (elIndex >= 0 && elIndex < props.instance.numberOfInputs) {
            inputs.value[elIndex].value = value[elIndex] && /\d+/.test(value[elIndex]) ? value[elIndex] : "";
        }
    }
}

const conversionEvents = () => {
    new ConversionEvents().fireEvents();
}

const inputsAreValid = () => inputs.value.filter(({value}) => value).length == props.instance.numberOfInputs;

watch(() => inputs.value[0].value, () => {
    if(inputs.value[0].value.length == props.instance.numberOfInputs) {
        const code = inputs.value[0].value.slice(0, props.instance.numberOfInputs)

        autofillInputs(code);
    }
});

</script>


import {createApp} from "vue";
import {createPinia} from 'pinia';
import './App/Assets/style.css';
import App from './App.vue';
import * as Sentry from "@sentry/browser";

Sentry.init({
    // dsn: "https://faf5da5fc32a473daf8b99cd33d5a11b@o1134946.ingest.us.sentry.io/6202198",
    dsn: import.meta.env.VITE_SENTRY_DSN,
  
    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    // release: "my-project-name@2.3.12",
    release: import.meta.env.VITE_SENTRY_RELEASE,
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT ?? 'local'
  });

const pinia = createPinia();

document.querySelectorAll('.flow-client').forEach((parent) => {
    const app = createApp(App, {...(parent as HTMLElement).dataset});

    app.use(pinia);
    app.mount(parent);
});
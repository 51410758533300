export default class DelayedScriptLoader {
    static loadAnalyticsScript(gtmId: string|null, gaId: string|null) {
        DelayedScriptLoader.loadGTM(gtmId);
        // DelayedScriptLoader.loadGA(gaId);
    }

    static loadGTM(gtmId: string|null) {
        //@ts-ignore
        if (window.dataLayer !== undefined) return;
        if (!gtmId || !gtmId.length) {
            console.error('GTM ID does not exist in the flow');
            return;
        }

        DelayedScriptLoader.injectScript(`
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${gtmId}');
            `);
    }

    static loadGA(gaId: string|null) {
        //@ts-ignore
        if (window.ga !== undefined) return;
        if (!gaId || !gaId.length) {
            console.error('GA ID does not exist in the flow');
            return;
        }

        DelayedScriptLoader.injectScript(`
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
            `);

        //@ts-ignore
        const isLoaded = () => window.ga !== undefined;
        const loadCheck = (cb: () => void) => {
            if(isLoaded())
                cb();
            else
                setTimeout(() => loadCheck(cb), 100);
        };

        loadCheck(() => {
            // @ts-ignore
            window.ga('create', gaId, 'auto', 'flowBuilder')
        })
    }

    static loadWatchDog(): void {
        if (!import.meta.env.VITE_WATCHDOG_ASSET_URL) {
            console.error(`Could not load WatchDog, missing URL`);
            return;
        }
        const watchDogUrl = `${import.meta.env.VITE_WATCHDOG_ASSET_URL}`
        DelayedScriptLoader.injectScript(watchDogUrl, true);
    }

    static injectScript(scriptContent: string, url = false): void {
        const scriptElement = document.createElement('script');
        if (url) scriptElement.src = scriptContent;
        else scriptElement.text = scriptContent;
        //@ts-ignore
        document.querySelector('head').appendChild(scriptElement);
    }
}
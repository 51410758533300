import Slide, {AutoProgressType} from "@flow-builder/core/src/Slides/Slide.ts";
import {TransitionType, useFlowStore} from "../Stores/flow.ts";
import {Branch} from "@flow-builder/core/src/Flows/Branch.ts";
import {useConsumerStore} from "../Stores/consumer.ts";
import {useErrorStore} from "../Stores/errors.ts";
import SessionStorageService from "./SessionStorageService.ts";
import {useEngineStore} from "../Stores/engines.ts";
import waitForCallbackToReturnTrue from "@flow-builder/core/src/Services/loading-utility.ts";
import {BaseAction, SlideActionType} from "@flow-builder/core/src/Blocks/Core/Actions/SlideAction.ts";
import {dispatchGtmEvent} from "../Composables/dispatchCustomEvent.ts";
import Block from "@flow-builder/core/src/Blocks/Core/Block.ts";
import {LegacyAction} from "@flow-builder/core/src/Blocks/Core/Actions/Action.ts";
import {
    SlideLifecycleEvent,
    SlideLifecycleEventType
} from "@flow-builder/core/src/Blocks/Core/Events/SlideLifecycleEvents.ts";
import {BranchHistory, BranchHistoryStorage} from "./BranchHistory.ts";
import {flagBlocksForConditionalRendering} from "./ConditionalRenderingService.ts";

export interface SliderServiceInterface {
    initializeService(): void

    nextSlide(): Slide | null

    previousSlide(): Slide | null

    goToSlide(slideId: string): Slide | null

    goToBranch(branchId: string): Slide | null

    changeIndustryService(serviceSlug: string): void;

    handleAction(actions: BaseAction[]): void

    getTemplates(): Slide[]
}

export default class SliderService implements SliderServiceInterface {
    public currentSlide: Slide | null = null;
    public currentBranch: Branch | null = null;

    protected flowStore = useFlowStore();
    protected consumerStore = useConsumerStore();
    protected activeSlides: Slide[] = [];
    protected activeBranch: Branch | null = null;
    protected flowSlides: Slide[] = [];
    protected branches: Branch[] = [];
    protected slidesHistory: string[] = [];
    protected templates: Slide[] = [];
    protected branchHistory: BranchHistory = new BranchHistory();
    protected errorStore = useErrorStore();
    protected engineStore = useEngineStore();

    initializeService(setCurrentSlide: boolean = true): void {
        this.activeSlides = this.flowStore.flow?.getSlides() ?? [];
        this.flowSlides = this.activeSlides;
        this.branches = this.flowStore.flow?.getBranches() ?? [];
        this.templates = this.flowStore.flow?.getTemplates() ?? [];

        if (setCurrentSlide) {
            this.currentSlide = this.flowStore.flow?.getStartingSlide() ?? this.flowStore.flow?.getSlides()[0] ?? null;

            if (this.currentSlide) {
                this.slidesHistory.push(this.currentSlide.id);
                this.flowStore.currentSlideId = this.currentSlide.id;
            }
        }

        this.errorStore.initialize();
    }

    public getTemplates(): Slide[] {
        return this.templates;
    }

    nextSlide(): Slide | null {
        const slideIndex = this.activeSlides.findIndex((slide: Slide) => slide.id === this.currentSlide?.id);

        if (slideIndex === -1) return null;

        const slide = this.activeSlides[slideIndex + 1] ?? null;

        // If this is the last slide in the set, and NOT the main flow, use Branch History to resume previous Branch
        if (!slide && this.activeBranch !== null && this.branchHistory.getHistory().length) {
            this.returnFromEndOfBranch();
            return null;
        }

        else if (slide) {
            this.setCurrentSlideAndAddHistory(slide);
        }
        return slide;
    }

    previousSlide(): Slide | null {
        if (this.slidesHistory.length === 1) {
            return null;
        }

        let slideId = this.slidesHistory.pop();
        const currentSlideId = this.currentSlide?.id ?? null;

        if (slideId && slideId === this.currentSlide?.id) {
            slideId = this.slidesHistory.pop();
        }

        this.updateHistoryInSessionStorage();

        if (slideId) {
            if (this.findSlideInFlowAndBranches(slideId, false)) {
                this.currentBranch = this.activeBranch;
                this.branchHistory.goBack(currentSlideId);
            }
        }

        return null;
    }

    goToLastSlide(): Slide | null {
        const slide = this.activeSlides.slice(-1).pop();

        this.setCurrentSlideAndAddHistory(slide);

        return slide;
    }

    goToSlide(slideId: string): Slide | null {
        const slide = this.activeSlides.find((slide: Slide) => slide.id === slideId);

        if (!slide) return this.findSlideInFlowAndBranches(slideId);

        this.setCurrentSlideAndAddHistory(slide);

        return slide;
    }

    goToBranch(branchId: string): Slide | null {
        const branch = this.branches.find((branch: Branch) => branch.id === branchId);

        if (!branch) return null;

        this.activeSlides = branch.slides;
        this.activeBranch = branch;

        const slide = this.activeSlides[0] ?? null;

        if (slide) this.setCurrentSlideAndAddHistory(slide, true);

        return slide;
    }

    refreshSlide(): Slide | null {
        const slide = this.currentSlide;

        if (slide) flagBlocksForConditionalRendering(slide);

        return slide;
    }

    async changeIndustryService(serviceSlug: string): Promise<void> {
        //TODO: Update this to avoid a second POST to Engines
        await this.flowStore.update(
            {'service': serviceSlug},
            this.consumerStore.bearerToken,
            { type: SlideActionType.None }
        ).catch(e => console.error(e));
    }

    async handleAction(actions: BaseAction[]): Promise<void> {
        actions.forEach((action) => {
            switch (action.type) {
                case SlideActionType.GoToLastSlide:
                    this.goToLastSlide();
                    break;
                case SlideActionType.GoToSlide:
                    this.goToSlide(action.target ?? "");
                    break;
                case SlideActionType.GoToBranch:
                    this.goToBranch(action.target ?? "");
                    break;
                case SlideActionType.PreviousSlide:
                    this.previousSlide();
                    break;
                case SlideActionType.ChangeIndustryService:
                    this.changeIndustryService(action.target ?? "");
                    break;
                case SlideActionType.GTM:
                case SlideActionType.GTMCustom:
                    dispatchGtmEvent(action);
                    break;
                case SlideActionType.ReturnFromBranch:
                    this.returnFromEndOfBranch();
                    break;
                case SlideActionType.RerenderSlide:
                    this.refreshSlide();
                    break;
                case SlideActionType.NextSlide:
                default:
                    this.nextSlide();
                    break;
            }
        });
    }

    public setSlideHistory(history: string[]) {
        this.slidesHistory = history
    }

    public setBranchHistory(history: BranchHistoryStorage | null) {
        if (history) {
            this.branchHistory.setFromStorage(history);
        }
    }

    protected setCurrentSlideAndAddHistory(slide: Slide, markBranchReturn = false): void {
        this.setNextTransitionType(slide);
        if (markBranchReturn) {
            this.makeBranchHistoryPoint();
        }

        //TODO: implement as part of Logic Store either on FlowStore or here
        flagBlocksForConditionalRendering(slide);

        this.currentSlide = slide;
        this.slidesHistory.push(slide.id);
        this.flowStore.currentSlideId = this.currentSlide.id;

        this.updateHistoryInSessionStorage();
    }

    protected setNextTransitionType(upcomingSlide: Slide): void {
        if (!this.currentSlide) return;
        const nextTransition = this.currentSlide.template
            ? upcomingSlide.template
                ? upcomingSlide.template === this.currentSlide.template
                    ? TransitionType.TemplateToTemplate
                    : TransitionType.ScreenToTemplate
                : TransitionType.TemplateToScreen
            : upcomingSlide.template
                ? TransitionType.ScreenToTemplate
                : TransitionType.ScreenToScreen;
        this.flowStore.setTransitionType(nextTransition);
    }

    public findSlideInFlowAndBranches(slideId: string, markBranchHistory = true): Slide | null {
        let slide = this.flowSlides.find((slide: Slide) => slide.id === slideId) ?? null;

        if (slide) {
            this.activeSlides = this.flowSlides;
            this.activeBranch = null;

            this.setCurrentSlideAndAddHistory(slide);

            return slide;
        }

        for (const branch of this.branches) {
            slide = branch.slides.find((slide: Slide) => slide.id === slideId) ?? null;

            if (slide) {
                this.activeSlides = branch.slides;
                this.activeBranch = branch;

                this.setCurrentSlideAndAddHistory(slide, markBranchHistory);

                return slide;
            }
        }

        return null;
    }

    /**
     * Note that this does not currently run through flowStore.update(), so inputs are not updated
     * @param slide
     */
    public async handleSlideAutoProgress(slide: Slide) {
        if (!slide.autoProgress?.enabled) return;

        switch (slide.autoProgress.type) {
            case AutoProgressType.TIMER:
                if (slide.autoProgress.time)
                    await this.wait(slide.autoProgress.time * 1000);
                break;
            case AutoProgressType.ENGINE_OUTPUT:
                if (slide.autoProgress.minWaitTime)
                    await this.wait(slide.autoProgress.minWaitTime * 1000);

                if (slide.autoProgress.engine && slide.autoProgress.outputKey)
                    await waitForCallbackToReturnTrue(
                        () => this.engineStore.getEngineOutputByEngineAndKey(slide.autoProgress.engine as string, slide.autoProgress.outputKey as string) !== null,
                        100,
                        200
                    )

                break;
        }

        const slideActions = 'actions' in slide.autoProgress
            ? Block.convertLegacyBlockActions(slide.autoProgress.actions as LegacyAction[])
            : slide.autoProgress.slideActions;

        const newEvent: SlideLifecycleEvent = {
            type: SlideLifecycleEventType.SLIDE_SUBMIT,
            trigger: slide,
            actions: slideActions ?? [],
        }

        this.flowStore.slideEventHandler.trigger(
            newEvent,
            this.flowStore.getInputCollection({}),
            slide,
        )
    }

    protected makeBranchHistoryPoint(): void {
        const targetSlides: Slide[] = this.currentBranch ? this.currentBranch.slides : this.flowSlides;
        const currentSlideIndex = targetSlides.findIndex(slide => slide.id === this.currentSlide?.id);

        if (currentSlideIndex === -1) {
            console.error(`Could not set branch history, slide not found in target branch.`);
            return;
        }
        else {
            const returnSlide = targetSlides[currentSlideIndex + 1] ?? null;
            if (returnSlide) {
                this.branchHistory.makeBranchHistoryPoint(returnSlide.id);
            }

            this.currentBranch = this.activeBranch;
            this.updateBranchHistoryInSessionStorage();
        }
    }

    protected returnFromEndOfBranch(): void
    {
        const targetSlideId = this.branchHistory.getReturnSlideFromEndOfBranch();
        if (!targetSlideId) {
            console.error("Could not return to previous branch flow, slide not found.");
        }

        if (this.findSlideInFlowAndBranches(targetSlideId, false)) {
            this.currentBranch = this.activeBranch;
        }
        this.updateBranchHistoryInSessionStorage();
    }

    protected updateHistoryInSessionStorage(){
        new SessionStorageService().consumerSlideHistory = this.slidesHistory;
    }

    protected updateBranchHistoryInSessionStorage() {
        new SessionStorageService().consumerBranchHistory = this.branchHistory.getStorage();
    }

    protected async wait(time: number) {
        return new Promise(resolve => setTimeout(resolve, time))
    }
}
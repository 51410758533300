<template>
    <Renderer :theme="theme" class="fb-flex-1" />
</template>

<script setup lang="ts">
import Renderer from "./Renderer/WebRenderer/Renderer.vue";
import Flow from "@flow-builder/core/src/Flows/Flow.ts";
import {defaultTheme} from "@flow-builder/core/src/Styling/Theme.ts";
import {SlideUpdateEvent, useFlowStore} from "./Stores/flow.ts";
import {Google} from "@flow-builder/core/src/Services/External/Google.ts";
import {usePayloadStore} from "./Stores/payload.ts";
import FlowPayload from "./Payload/FlowPayload.ts";
import {ClientFlowResponse, getFlow} from "./Composables/getFlowService.ts";
import {Ref, ref} from "vue";
import {useConsumerStore} from "./Stores/consumer.ts";
import {useEngineStore} from "./Stores/engines.ts";
import DelayedScriptLoader from "./Services/DelayedScriptLoader.ts";
import SessionStorageService from "./Services/SessionStorageService.ts";
import {SlideActionType} from "@flow-builder/core/src/Blocks/Core/Actions/SlideAction.ts";

const props = defineProps({
    flow: {
        type: String,
        default: null
    },
    revision: {
        type: String,
        default: null,
    },
    initial: {
        type: Object,
        default: null
    },
    workingRevision: {
        type: Boolean,
        default: false,
    }
});

const flowStore = useFlowStore();
const payloadStore = usePayloadStore();
const consumerStore = useConsumerStore();
const engineStore = useEngineStore();
const sessionStorageService = new SessionStorageService();

const flowInstance = new Flow();
const theme = defaultTheme;

const flowItem: Ref<ClientFlowResponse|null> = ref(null);

const flowId = (import.meta.env.VITE_ENVIRONMENT === 'local' && import.meta.env.VITE_TEST_FLOW_ID)
    ? import.meta.env.VITE_TEST_FLOW_ID
    : props.flow ?? '';

//@ts-ignore
const revisionId = (import.meta.env.VITE_ENVIRONMENT === 'local' && import.meta.env.VITE_TEST_REVISION_ID)
    ? import.meta.env.VITE_TEST_REVISION_ID
    : (window.flowRevision ?? props.revision) ?? '';

const workingBranch = ((import.meta.env.VITE_ENVIRONMENT === 'local' && import.meta.env.VITE_TEST_WORKING_BRANCH)
                || props.workingRevision);

flowStore.showLoading = false;

const flowResponse = await getFlow(flowId, revisionId, workingBranch);
if (!flowResponse) {
    console.warn(`Failed to fetch revision: ${revisionId}`);
}

flowItem.value = flowResponse;

flowStore.flowKey = flowItem.value?.flowKey || null;

flowInstance.update(
    flowItem.value?.flowJson
);

const engines = flowInstance.getEngines().map((item) => item.name);

flowStore.flow = flowInstance;
payloadStore.flowPayload = new FlowPayload();

const initializeWatchdog = () => {
    if (flowStore?.flow?.getWatchdogEnabled()) flowStore.initializeWatchDog();
};

if (!(flowStore.flow?.shouldKeepState() ?? false) || !sessionStorageService.consumerToken) {
    flowStore.sliderService.initializeService();

    // @ts-ignore
    window.calculatorRendered = true;

    const IndustryService = flowStore.flow?.getDefaultService().length ? flowStore.flow.getDefaultService() : undefined;

    flowStore.flowApiService.handshake(flowStore.flowKey as string, engines, IndustryService, flowResponse?.deletedFlow).then(async resp => {
        consumerStore.bearerToken = resp.data?.data?.bearer_token ?? '';
        consumerStore.tokenExpiresAt = resp.data?.data?.expires_at ?? null;

        sessionStorageService.consumerToken = consumerStore.bearerToken;

        await new Promise((resolve) => setTimeout(() => resolve(), 25));

        if(props.initial !== null) {
            engineStore.service.addReadyCallback(async () => {
                const payload = payloadStore.flowPayload?.getInitialSlidePayload(flowStore.sliderService.currentSlide?.id ?? '');

                // Temporary workaround for safecall/top installer experiment with pre-filled zip code from outer website
                const initialProps: {[key: string]: any} = {};
                if (payload && 'zip_code_top_installers' in payload && props.initial?.zip_code) {
                    initialProps.zip_code_top_installers = props.initial.zip_code;
                }
                else {
                    Object.assign(initialProps, props.initial);
                }

                const canProgress = Object.keys(payload).every((key) => (initialProps)[key] !== undefined);

                const slideUpdateEvent: SlideUpdateEvent = {
                    type: canProgress
                        ? SlideActionType.NextSlide
                        : SlideActionType.None,
                }

                await flowStore.update(initialProps, consumerStore.bearerToken, slideUpdateEvent, true);

                initializeWatchdog();

                flowStore.showLoading = false;
                // @ts-ignore
                window.calculatorLoaded = true;
            })
        } else {
            flowStore.showLoading = false;
            // @ts-ignore
            window.calculatorLoaded = true;

            initializeWatchdog();
        }

        engineStore.engines = resp.data?.data?.engines ?? [];
        engineStore.service.listenForResults(consumerStore.bearerToken);

        sessionStorageService.engines = engineStore.engines;
        sessionStorageService.engineInputs = engineStore.inputs;
        sessionStorageService.engineOutputs = engineStore.outputs;
    });
} else {
    sessionStorageService.initializeFromSessionStorage();
}

new Google().loadService();

DelayedScriptLoader.loadAnalyticsScript(flowStore.flow?.getGtmId() ?? null, flowStore.flow?.getGaId() ?? null);
if (flowStore?.flow?.getWatchdogEnabled()) DelayedScriptLoader.loadWatchDog();

</script>
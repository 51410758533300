<template>
    <div :class="layoutClasses">
        <date-card @click="handleDateClick(date)" v-for="(date, idx) in availableDates" :key="idx" :date="date"/>
    </div>
</template>

<script setup lang="ts">
import {computed} from "vue";
import AppointmentDateSelect from "@flow-builder/core/src/Blocks/Core/Inputs/AppointmentDateSelect.ts";
import DateCard from './DateCard.vue'
import BlockPayload from "../../../../Payload/BlockPayload.ts";
import {SlideUpdateEvent, useFlowStore} from "../../../../Stores/flow.ts";
import {usePayloadStore} from "../../../../Stores/payload.ts";
import {useConsumerStore} from "../../../../Stores/consumer.ts";
import {useErrorStore} from "../../../../Stores/errors.ts";
import {ErrorResponse} from "../../../../Errors/SlideErrors.ts";
import {DateTime, DateTimeFormatOptions} from "luxon";
import {useGridColumnOverride} from "@flow-builder/core/src/Components/Renderer/Composables/useGridColumnOverride.ts";
import {SlideActionType} from "@flow-builder/core/src/Blocks/Core/Actions/SlideAction.ts";
import {BlockJson} from "@flow-builder/core/src/Blocks/Core/Block.ts";

interface Props {
    instance: AppointmentDateSelect,
}
const props = defineProps<Props>();

const GRID_GAP_MAPPINGS = ['fb-gap-1', 'fb-gap-2', 'fb-gap-3', 'fb-gap-4', 'fb-gap-5', 'fb-gap-6', 'fb-gap-7', 'fb-gap-8', 'fb-gap-9', 'fb-gap-10', 'fb-gap-11', 'fb-gap-12'];


const flowStore = useFlowStore();
const payloadStore = usePayloadStore();
const consumerStore = useConsumerStore();
const errorStore = useErrorStore();

const buildPayload = (date) => new BlockPayload(
    flowStore.sliderService.currentSlide?.id ?? '',
    {
        [props.instance.backendIdentifier]: date
    },
    props.instance.id
);

const handleDateClick = (date) => {
    const onlyDate = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')

    payloadStore.flowPayload?.setBlockPayload(buildPayload(onlyDate));

    errorStore.slideErrors.resetSlideError(flowStore.sliderService.currentSlide?.id ?? '');

    const newEvent: SlideUpdateEvent = {
        type: SlideActionType.NextSlide,
        trigger: props.instance as BlockJson,
        actions: [],
    }

    flowStore.update(
        payloadStore?.flowPayload?.getSlidePayload(flowStore.sliderService.currentSlide?.id ?? ''),
        consumerStore.bearerToken,
        newEvent
    ).catch(e => errorStore.slideErrors.processErrorResponse(e.response as ErrorResponse, flowStore.sliderService.currentSlide?.id ?? ''));
}

const availableDates = computed(() => {
    return getAvailableDates(
        props.instance.totalDaysAvailable,
        props.instance.daysToAddToFirstDate,
        !!props.instance.avoidWeekend
    )
})

/**
 * Add days to date
 * @param date
 * @param days
 */
const addDays = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

const checkIfItsWeekend = (date: Date) => {
    const [day] = date.toDateString().split(' ')

    return ['sat', 'sun'].includes(day.toLowerCase())
}


const getNextDate = (startDate: Date, avoidWeekend: boolean, dates: Array<Object>) => {
    startDate = addDays(startDate, 1);

    while ((avoidWeekend && checkIfItsWeekend(startDate))) {
        startDate = addDays(startDate, 1);
    }

    return startDate;
}

const getAvailableDates = (quantity: number, daysToAddToFirstDate: number, avoidWeekend: boolean) => {
    let startDate = addDays(new Date(), daysToAddToFirstDate - 1);

    const dates = [];

    for (let i = 0; i < quantity; i++) {
        startDate = getNextDate(startDate, avoidWeekend, dates);
        dates.push(startDate)
    }

    return dates;
}

const layoutClasses = computed(() => {
    return `fb-grid ${useGridColumnOverride(props.instance.gridCols, props.instance,  true)} ${GRID_GAP_MAPPINGS[props.instance.gridGap - 1]} fb-items-start`;
});

</script>